#downfence {
  width: 4rem;
  height: 4rem;

  position: relative;
  animation: animateLoader3 6s ease-out infinite;
  background-color: #f3722c;
}

@keyframes animateLoader3 {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(1);
  }

  80%,
  100% {
    opacity: 0;
  }
}
